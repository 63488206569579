import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Drawer, Badge, Menu, Select } from "antd";
import {
  MenuOutlined,
  LogoutOutlined,
  ShoppingCartOutlined,
  CloseOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";
import { getUserProducts } from "../service/productApi";
import home from "../assets/home.png";
import { FormattedMessage, IntlProvider } from "react-intl";
import pa from "../pa.json";
import en from "../en.json";
import hi from "../hi.json";

const messages = {
  en,
  hi,
  pa,
};

const { Option } = Select;

const Navbar = ({ length }) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const [len, setLength] = useState();
  const [data, setData] = useState();
  const [locale, setLocale] = useState("en");

  useEffect(() => {
    getProductItem();
    getSetuser();
  }, []);

  useEffect(() => {
    setLength(length);
  }, [length, len]);

  const getProductItem = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));

    if (!userData || !userData._id) {
      console.error("User data not found or missing _id property");
      return;
    }

    try {
      const data = await getUserProducts(userData._id);
      setLength(data?.length);
    } catch (error) {
      console.error("Error fetching user products:", error);
    }
  };

  const getSetuser = () => {
    const user = localStorage.getItem("user");
    setData(user);
  };

  const changeLanguage = (newLocale) => {
    setLocale(newLocale);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleDrawerItemClick = (path) => {
    navigate(path);
    handleDrawerClose();
  };

  const handleOrder = () => {
    navigate("/orders");
  };

  const handleClick = () => {
    localStorage.clear();
    setLength(0);
    navigate("/");
    getSetuser();
  };

  const handleCart = () => {
    if (!data) {
      navigate("/login");
    } else {
      navigate("/cart");
    }
  };

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <div className="p-2 ml-2 mr-2 font-sans text-[16px] font-semibold">
        <div className="container mx-auto py-4">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <div className="mr-4 cursor-pointer md:hidden">
                <MenuOutlined
                  style={{ fontSize: "24px", color: "#503c3c" }}
                  onClick={handleDrawerOpen}
                />
              </div>
              <Link to="/" className="flex items-center">
                <img
                  src={home}
                  className="h-12 w-12 sm:h-16 sm:w-16 md:h-20 md:w-20 lg:h-20 lg:w-20"
                  alt="Home"
                />
              </Link>
            </div>
            <div className="hidden md:flex">
              <h1 className="font-bold text-3xl text-[#503c3c] font-comic-sans">
                <FormattedMessage id="title" />
              </h1>
            </div>
            <div className="flex items-center">
              <Select
                className="mr-4 font-bold hidden md:block"
                defaultValue="en"
                onChange={(value) => changeLanguage(value)}
              >
                <Option value="en">English</Option>
                <Option value="hi">Hindi</Option>
                <Option value="pa">Punjabi</Option>
              </Select>
              {data ? (
                <Badge count={len} overflowCount={999}>
                  <ShoppingOutlined
                    style={{ fontSize: "24px", color: "#503c3c" }}
                    onClick={handleOrder}
                  />
                </Badge>
              ) : null}
              <button className="ml-4" onClick={handleCart}>
                <Badge count={len} overflowCount={999}>
                  <ShoppingCartOutlined
                    style={{ fontSize: "24px", color: "#503c3c" }}
                  />
                </Badge>
              </button>
              {!data ? (
                <>
                  <Link to="/register" className="ml-4">
                    <button className="text-[#503c3c] bg-gray-100 p-2 rounded hover:bg-[#503c3c] hover:text-gray-100">
                      <FormattedMessage id="register" />
                    </button>
                  </Link>
                  <Link to="/login" className="ml-4 min-w-20">
                    <button className="text-gray-100 bg-[#503c3c]  p-2 rounded hover:bg-gray-100 hover:text-[#503c3c]">
                      <FormattedMessage id="signIn" />
                    </button>
                  </Link>
                  <Link to="/Video" className=" text-[#503c3c] bg-gray-100 p-2 rounded hidden md:block">
                    <button className="text-[#503c3c]">
                      <FormattedMessage id="poojaTutorials" />
                    </button>
                  </Link>
                </>
              ) : (
                <button className="ml-4" onClick={handleClick} title="Logout">
                  <LogoutOutlined style={{ fontSize: "24px" }} />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Menu Drawer */}
      <Drawer
        width={220}
        title=""
        placement="left"
        closable={false}
        onClose={handleDrawerClose}
        open={isDrawerOpen}
      >
        <div className="text-right p-2">
          <CloseOutlined
            onClick={handleDrawerClose}
            style={{ fontSize: "24px", fontWeight: "bold", strokeWidth: "1.5" }}
          />
        </div>
        <div className="flex justify-center mb-4">
          <Link to="/" onClick={handleDrawerClose}>
            <img src={home} className="h-12 w-12 sm:h-16 sm:w-16 md:h-20 md:w-20 lg:h-24 lg:w-24" alt="Home" />
          </Link>
        </div>
        <Menu
          className="font-bold text-[16px] w-auto"
          onClick={(e) => handleDrawerItemClick(e.key)}
          mode="vertical"
        >
          <Menu.Item key="/">
            <FormattedMessage id="home" />
          </Menu.Item>
          <Menu.Item key="/cart">
            <Badge count={len} overflowCount={999}>
              <ShoppingCartOutlined style={{ fontSize: "24px" }} />
            </Badge>
          </Menu.Item>
          <Menu.Item key="/register">
            <FormattedMessage id="register" />
          </Menu.Item>
          <Menu.Item key="/login">
            <FormattedMessage id="signIn" />
          </Menu.Item>
          <Menu.Item key="/Donation">
            <FormattedMessage id="donation" />
          </Menu.Item>
          <Menu.Item key="/Video">
            <FormattedMessage id="poojaTutorials" />
          </Menu.Item>
          {data && (
            <Menu.Item key="logout">
              <FormattedMessage id="logout" />
            </Menu.Item>
          )}
          <Menu.Item>
            <Select
              defaultValue="en"
              onChange={(value) => changeLanguage(value)}
            >
              <Option value="en">English</Option>
              <Option value="hi">Hindi</Option>
              <Option value="pa">Punjabi</Option>
            </Select>
          </Menu.Item>
        </Menu>
      </Drawer>
    </IntlProvider>
  );
};

export default Navbar;
