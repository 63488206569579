import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getProduct } from "../service/productApi";
import { DeleteOutlined } from "@ant-design/icons";
import { publicRequest } from "../requestMethods";

const ProductContainer = styled.div`
  display: flex;
  border: 1px solid black;
  border-radius: 20px;
  overflow: hidden;
  margin-left: 10px;
  margin-bottom: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 70vw;
`;

const ProductDetail = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
`;

const Image = styled.img`
  width: auto;
  height: 250px;
  margin-right: auto;
  max-width: 200px;
  border-radius: 20px;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const Details = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const PanditName = styled.span`
  font-size: 24px;
  font-weight: bold;
  color: #503c3c;
`;

const ProductId = styled.span`
  font-size: 16px;
  color: #000000;
`;

const PanditType = styled.span`
  font-size: 14px;
  color: #666;
`;

const PriceDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const ProductPrice = styled.div`
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 20px;
`;

const Icon = styled.div`
  margin-top: 20px;
  margin-right: 20px;
  height: 20px;

  &:hover {
    color: red;
    cursor: pointer;
  }
`;

const ProductCard = ({ product, getUserProductsList }) => {
  const [productDetails, setProductDetails] = useState(null);

  useEffect(() => {
    getProductDetails();
  }, [product.productId]);

  const getProductDetails = async () => {
    try {
      const data = await getProduct(product.productId);
      setProductDetails(data);
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  };

  const deleteProduct = async () => {
    try {
      const res = await publicRequest.delete(`/carts/${product.productId}`);
      if (res) {
        alert("Item has been deleted successfully");
        getUserProductsList();
      }
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  return (
    <ProductContainer>
      <Container>
        <ProductDetail>
          <Image src={productDetails?.img} alt="Product Image" />
          <Details>
            <PanditName>{productDetails?.title}</PanditName>
            <ProductId>ID: {product.productId}</ProductId>
            <PanditType>Pandit Preference: {product.panditType}</PanditType>
          </Details>
        </ProductDetail>
        <PriceDetail>
          <ProductPrice>Total: ₹ {productDetails?.price * product.quantity}</ProductPrice>
        </PriceDetail>
      </Container>
      <Icon>
        <DeleteOutlined onClick={deleteProduct} />
      </Icon>
    </ProductContainer>
  );
};

export default ProductCard;
