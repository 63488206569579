import React, { useState } from "react";
import { EyeOutlined, EyeInvisibleOutlined, UserOutlined } from "@ant-design/icons";
import { Input, Button } from "antd";
import { useSelector } from "react-redux";
import { publicRequest } from "../requestMethods";
import { useNavigate, Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import home from "../assets/home.png"; // Ensure the correct path to your logo image

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const { isFetching } = useSelector((state) => state.user);

  const handleClick = async (e) => {
    e.preventDefault();

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = {
      username: username,
      password: password,
    };

    try {
      const response = await publicRequest.post("/auth/login", body, config);

      if (response.status === 200) {
        const userData = response.data;
        localStorage.setItem("user", JSON.stringify(userData));
        toast.success("Login successful");
        navigate("/");
      } else {
        console.error("Login failed - response:", response);
        toast.error("Login failed. Please try again.");
      }
    } catch (error) {
      console.error("Login error:", error);
      setError("Something went wrong.");
      toast.error("Something went wrong.");
    }
  };

  return (
    <div className="bg-home min-h-screen bg-slate-50">
      <Navbar />
      <div className="p-4 flex w-auto items-center text-[#503c3c] min-h-[80vh] justify-center bg-slate-200">
        <div className="w-full max-w-2xl bg-white p-10 rounded-lg shadow-lg">
          <div className="flex justify-center mb-8">
            <Link to="/">
              <img
                src={home}
                className="h-16 w-16 md:h-20 md:w-20 lg:h-24 lg:w-24"
                alt="Home"
              />
            </Link>
          </div>
        
          <h1 className="text-3xl font-semibold text-center mb-8 font-poppins">SIGN IN</h1>
          <form onSubmit={handleClick} className="grid grid-cols-1 gap-6">
            <Input
              placeholder="Username"
              autoComplete="username"
              onChange={(e) => setUsername(e.target.value)}
              className="w-full"
              prefix={<UserOutlined />}
            />
            <Input.Password
              placeholder="Password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
              iconRender={(visible) =>
                visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
              }
              className="w-auto"
              prefix={<EyeInvisibleOutlined />}
            />
            <Button
              type="primary"
              htmlType="submit"
              className="w-auto bg-[#503c3c] p-2 font-comic-sans text-xl hover:bg-black"
              loading={isFetching}
            >
              Login
            </Button>
            {error && <span className="text-red-500">{error}</span>}
            <div className="flex flex-col justify-between text-sm mt-4">
              <Link to="/register" className="text-brown font-semibold hover:underline">
                CREATE A NEW ACCOUNT
              </Link>
              <Link to="/forget-password" className="text-brown font-semibold hover:underline">
                Forget Password
              </Link>
            </div>
          </form>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default Login;
