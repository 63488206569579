// Newsletter.js

import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import styled from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import { SendOutlined } from "@ant-design/icons";

const Input = styled.input`
  border: 1px solid #000000;
  padding-left: 20px;
`;



const Newsletter = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://poojawala-backend.onrender.com/api/newsletter", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        toast.success("Subscription successful!");
      } else {
        toast.error("Subscription failed. Please try again later.");
      }
    } catch (error) {
      console.error("Error during subscription:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="flex flex-col justify-center items-center h-40vh text-white bg-[#503c3c] mb-2 p-10 font-comic-sans">
      <h1 className="text-6xl md:text-5xl mb-10">Newsletter</h1>
      <div className="flex flex-wrap text-base font-light mb-5 md:text-lg md:font-normal">
        🎉 Subscribe now to receive daily exciting offers and divine Aartis of Bhagwan in your inbox! <br />
        🌟 Embrace the joy of savings and spiritual bliss. Don't miss out! 💌✨.
      </div>
      <form onSubmit={handleSubmit}>
        <div className="h-10 flex rounded justify-center">
          <Input className="text-black rounded mr-1"
            type="email"
            placeholder="Your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit" className=" w-8 bg-[#f8e6e1] text-[#503c3c] rounded border-black cursor-pointer flex items-center justify-center">
            <SendOutlined />
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Newsletter;
