import React, { useEffect, useState } from 'react';
import { DownloadOutlined, EyeOutlined, EllipsisOutlined } from '@ant-design/icons';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { Avatar, Card, Modal } from 'antd';
import { saveAs } from 'file-saver';
import { GalleryItems } from '../data';
import home from "../assets/home.png"


const { Meta } = Card;

const Gallery = () => {
  const [visible, setVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const slideLeft = () => {
    const slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft - 310;
  };

  const slideRight = () => {
    const slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft + 310;
  };

  const handleView = (img) => {
    setSelectedImage(img);
    setVisible(true);
  };

  const handleDownload = (img) => {
    saveAs(img, 'downloaded-image.jpg');
  };

  useEffect(() => {
    const interval = setInterval(() => {
      slideRight();
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <h2 className="font-bold font-comic-sans text-[#502a2a] text-2xl text-center">
        The Pooja performed by our Pandit
      </h2>
      <div className="relative flex items-center my-5 h-[380px]">
        <MdChevronLeft 
          size={40} 
          onClick={slideLeft} 
          className="bg-black text-white rounded-full absolute opacity-100 z-10 shadow-md cursor-pointer left-0 hover:bg-white hover:text-black"
        />
        <div id="slider" className="w-full h-full overflow-x-scroll whitespace-nowrap scroll-smooth scrollbar-hide p-3">
          {GalleryItems.map((item, index) => (
            <div key={index} className="inline-block mx-2 transform transition-transform hover:scale-105">
              <Card
                style={{ width: 300, border: '1px solid black' }}
                cover={
                  <img
                    alt={item.title}
                    src={item.img}
                    className="h-[220px] object-fit rounded-t-lg p-2"
                  />
                }
                actions={[
                  <DownloadOutlined key="download" onClick={() => handleDownload(item.img)} />,
                  <EyeOutlined key="view" onClick={() => handleView(item.img)} />,
                  <EllipsisOutlined key="ellipsis" />,
                ]}
                className="rounded-lg shadow-lg"
              >
                <Meta
                  avatar={<Avatar src={home} />}
                  title={item.title}
                  description={item.description}
                />
              </Card>
            </div>
          ))}
        </div>
        <MdChevronRight 
          size={40} 
          onClick={slideRight} 
          className="bg-black text-white rounded-full absolute opacity-100 z-10 shadow-md cursor-pointer right-0 hover:bg-white hover:text-black"
        />
      </div>
      <Modal
        visible={visible}
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <img alt="Enlarged View" style={{ width: '100%' }} src={selectedImage} />
      </Modal>
    </>
  );
};

export default Gallery;
