import React, { useState } from "react";
import styled from "styled-components";
import Announcement from "../components/Announcement";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Newsletter from "../components/Newsletter";
import Products from "../components/Products";
import { useLocation } from "react-router";

const Container = styled.div``;

const Title = styled.h1`
  margin: 20px;
  font-size: 20px;
  font-weight: 500;
`;


const ProductList = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const cat = pathSegments.length >= 3 ? pathSegments[2] : null;
  console.log("Category is:", cat);

  const [filters] = useState({});
  const [sort] = useState("newest");


  return (
    <Container>
      <Navbar />
      <Announcement />
      <Title>{cat}</Title>
      <Products cat={cat} filters={filters} sort={sort} />
      <Newsletter />
      <Footer />
    </Container>
  );
};

export default ProductList;
