import React, { useState } from "react";
import { EyeOutlined, EyeInvisibleOutlined, EnvironmentOutlined } from "@ant-design/icons";
import { useNavigate, Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import { Input, Checkbox, Radio, Button, message } from "antd";
import home from "../assets/home.png";
import { publicRequest } from "../requestMethods"; // Import the request method

const Register = () => {
  const [username, setUsername] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [userType, setUserType] = useState("user");
  const [passwordError, setPasswordError] = useState("");
  const [termsChecked, setTermsChecked] = useState(false);
  const navigate = useNavigate();

  const isPasswordValid = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/;
    return passwordRegex.test(password);
  };

  const handlePasswordBlur = () => {
    if (password && !isPasswordValid(password)) {
      setPasswordError(
        "Password must contain at least one uppercase letter, one lowercase letter, one special character, one number, and be between 8 to 16 characters long."
      );
    } else {
      setPasswordError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!username || !email || !password || !fname || !lname || !confirmPassword) {
      message.error("Please fill in all fields");
    } else if (password !== confirmPassword) {
      message.error("Passwords do not match");
    } else if (!termsChecked) {
      message.error("Please accept the terms and conditions");
    } else if (passwordError) {
      message.error(passwordError);
    } else if (phone.length !== 10) {
      message.error("Phone Number should be ten digits");
    } else {
      const body = {
        username,
        email,
        password,
        phone,
        address,
        fname,
        lname,
        userType,
      };
      try {
        const response = await publicRequest.post("/auth/register", body);
        if (response.status === 200) {
          message.success("Registered Successfully");
          navigate("/login");
        } else {
          message.error("Registration Failed");
        }
      } catch (error) {
        console.error("Registration error:", error);
        message.error("Registration Failed");
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const fetchCurrentLocation = () => {
    // Logic to fetch current location using Google Maps API
    // Once fetched, update the address state
    const currentAddress = "Current Address"; // Placeholder for fetched address
    setAddress(currentAddress);
  };

  return (
    <div className="bg-home min-h-screen">
      <Navbar />
      <div className=" p-4 flex items-center text-[#503c3c] justify-center bg-slate-100 ">
        <div className="w-full max-w-screen-lg bg-white p-8 rounded-lg shadow-lg">
          <div className="flex justify-center mb-8">
            <Link to="/">
              <img
                src={home}
                className="h-16 w-16 md:h-20 md:w-20 lg:h-24 lg:w-24"
                alt="Home"
              />
            </Link>
          </div>

          <h1 className="text-3xl font-semibold text-center mb-8 font-poppins">CREATE AN ACCOUNT</h1>
          <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <Input
              name="fname"
              value={fname}
              placeholder="First Name"
              onChange={(e) => setFname(e.target.value)}
              className="w-full"
              autoComplete="given-name"
            />
            <Input
              name="lname"
              value={lname}
              placeholder="Last Name"
              onChange={(e) => setLname(e.target.value)}
              className="w-full"
              autoComplete="family-name"
            />
            <Input
              name="username"
              value={username}
              placeholder="Username"
              onChange={(e) => setUsername(e.target.value)}
              className="w-full"
              autoComplete="username"
            />
            <Input
              name="email"
              value={email}
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              className="w-full"
              autoComplete="email"
            />
            <Input.Password
              name="password"
              value={password}
              placeholder="Password"
              onBlur={handlePasswordBlur}
              onChange={(e) => setPassword(e.target.value)}
              iconRender={(visible) =>
                visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
              }
              className="w-full"
              autoComplete="new-password"
            />
            {passwordError && <span className="text-red-500">{passwordError}</span>}
            <Input.Password
              name="cpassword"
              value={confirmPassword}
              placeholder="Confirm Password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              iconRender={(visible) =>
                visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
              }
              className="w-full"
              autoComplete="new-password"
            />
            <Input
              name="phone"
              value={phone}
              placeholder="Phone Number"
              onChange={(e) => setPhone(e.target.value)}
              className="w-full"
              autoComplete="tel"
            />
            <Input
              name="address"
              value={address}
              placeholder="Address"
              onChange={(e) => setAddress(e.target.value)}
              suffix={<EnvironmentOutlined onClick={fetchCurrentLocation} />}
              className="w-full"
              autoComplete="street-address"
            />
            <div className="flex items-center space-x-4">
              <Radio.Group onChange={(e) => setUserType(e.target.value)} value={userType}>
                <Radio value="user">User</Radio>
                <Radio value="pandit">Pandit</Radio>
              </Radio.Group>
            </div>
            <div className="flex items-center space-x-2">
              <Checkbox
                checked={termsChecked}
                onChange={(e) => setTermsChecked(e.target.checked)}
              />
              <span className="text-xs">
                By Creating an Account, I Consent to the Processing of my Personal
                data in accordance with the PRIVACY POLICY
              </span>
            </div>
            <Button type="primary" htmlType="submit" className="w-full bg-[#503c3c] p-2 font-comic-sans text-xl hover:bg-black">
              Create
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
