import React from 'react';
import Cart from './pages/Cart';
import Home from "./pages/Home";
import Login from './pages/Login';
import Product from './pages/Product';
import ProductList from './pages/ProductList';
import Register from './pages/Register';
import { Navigate, Route, Routes } from "react-router-dom";
import Success from "./pages/Success";
import { useSelector } from 'react-redux';
import Orders from './pages/Orders';
import Video from './pages/Video';
import Component from './pages/Component';
import Donation from "./pages/Donation";
import './index.css';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


function App() {
  const user = useSelector((state) => state.user.currentUser);
  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/products/:category" element={<ProductList />} />
        <Route path="/product/:id" element={<Product />} />
        <Route path="/orders" element={<Orders/>}/>
        <Route path="/cart" element={<Cart/>} />
        <Route path="/success" element={<Success />} />
        <Route path="/login" element={user ? <Navigate to="/" /> : <Login />} />
        <Route path="/register" element={user ? <Navigate to="/" /> : <Register />} />
        <Route path="/Video" element={<Video />} />
        <Route path='/Donation' element={<Donation />} />
        <Route path='/Component' element={<Component />} />
      </Routes>
    </>
  )
}

export default App;
