import React from 'react';

const Component = () => {
  return (
    <div className="p-6 max-w-sm mx-auto bg-white rounded-xl shadow-md space-y-4">
      <div className="text-xl font-2xl text-black">Welcome to Tailwind CSS</div>
      <p className="text-gray-500">This is a React component styled with Tailwind CSS.</p>
    </div>
  );
};

export default Component;
