import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { mobile } from '../responsive';
import { getProduct } from '../service/productApi';
import { formatDistanceToNow } from 'date-fns';


const ProductDetail = styled.div`
  flex: 2;
  display: flex;
`
const Image = styled.img`
  width: 200px;
`
const Details = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`
const PanditName = styled.span`
  font-size: 20px;
`

const ProductColor = styled.div`
  width: 20px;
  height: 20px;
  border-radius:50%;
  background-color: ${props => props.color};
`

const PriceDetail = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const ProductAmountContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`
const ProductPrice = styled.div`
  font-size: 30px;
  font-weight: 200;
  ${mobile({ marginBottom: "20px" })}
`
const Hr = styled.hr`
  background-color: #eee;
  border: none;
  height: 1px;
`

function OrderItem({ product }) {
    const [productData, setProductData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getProduct(product.products[0].productId);
                setProductData(data);
            } catch (error) {
                console.error('Error fetching product:', error);
            }
        };

        fetchData();
    }, [product.products]);

    const TimeAgo = ({ timestamp }) => {
        const timeAgo = formatDistanceToNow(new Date(timestamp));
        return <span>{timeAgo} ago</span>;
    }

    return (
        <>
            <div style={{ display: "flex" }}>
                <ProductDetail>
                    {productData && (
                        <>
                            <Image src={productData.img} />
                            <Details>
                                <PanditName><b>Product:</b> {productData.title}</PanditName>
                                <PanditName><b>Product ID:</b> {product.products[0].productId}</PanditName>
                                <PanditName><b>Price Per Item:</b> ₹ {product.amount}</PanditName>
                                <ProductColor color={productData.color} />
                                <PanditName><b>Ordered At:</b> <TimeAgo timestamp={new Date(product.createdAt)} /></PanditName>
                            </Details>
                        </>
                    )}
                </ProductDetail>
                <PriceDetail>
                    <ProductAmountContainer>
                        <PanditName><b>Quantity:</b> {product.products[0].quantity}</PanditName>
                    </ProductAmountContainer>
                    <ProductPrice>₹ {product.amount * product.products[0].quantity}</ProductPrice>
                </PriceDetail>
            </div>
            <Hr />
        </>
    );
}

export default OrderItem;
